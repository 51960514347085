import React from 'react';
import TEST from './test/test';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <TEST/>
      </header>
    </div>
  );
}

export default App;
