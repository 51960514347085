import React from 'react';
var fs = require('fs');
class TEST extends React.Component{
    constructor(props)
    {
        super(props);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.fileReader = null;
        this.inputRef = null;
        this.state = {
            originText:"",
            resultText:""
        }
    }
    handleFileRead = (e) => {
        var content = this.fileReader.result;
        var contentS = content.split("\n");
        var wordRef = {};
        var maximumRepeat = 0;
        var maximumRepeatWord = "";
        for(var i = 0 ;i < contentS.length; i++)
        {
            if(Number(contentS[i]) === 0 || contentS[i].length === 0) continue;
                var contentS_Words = contentS[i].split(/ /);
                for(var j = 0 ; j < contentS_Words.length; j++)
                {
                    if(Number(contentS_Words[j]) === 0 || contentS_Words[j].length === 0) continue;
                        if(wordRef[contentS_Words[j]]){
                            wordRef[contentS_Words[j]] ++;
                        } else {
                            wordRef[contentS_Words[j]] = 1;
                        }
                        if(wordRef[contentS_Words[j]] > maximumRepeat)
                        {
                            maximumRepeat = wordRef[contentS_Words[j]];
                            maximumRepeatWord = contentS_Words[j];
                        }
                }
           
        }
        var res = content.split(maximumRepeatWord).join("foo" + maximumRepeatWord + "bar")
        this.setState({
            originText: content,
            resultText: res
        })
    }
    handleFileChange = (file) => {
        this.fileReader = new FileReader();
        this.fileReader.onloadend = this.handleFileRead;
        this.fileReader.readAsText(file);
    }
    render(){
        return(
            <div>
                <input
                    ref={(input) => (this.inputRef = input)}
                    type = "file"
                    id = "file_input"
                    accept = ".txt"
                    onChange = {e => this.handleFileChange(e.target.files[0])}
                />
                <div style = {{display:"flex"}}>
                    <div >
                        <textarea style = {{width:"400px", height:"500px"}} defaultValue = {this.state.originText? this.state.originText:""}/>
                    </div>
                    <div>
                        <textarea style = {{width:"400px", height:"500px"}} defaultValue = {this.state.resultText? this.state.resultText:""} />
                    </div>
                </div>
            </div>
        )
    }
}
export default TEST;